import { API } from "../../settings/server.config";
import { useTableFetch } from "./fetchs";

const url = API.USER.PAGE();

export const useUserList = (
  { filterName, enableFetch } : 
  { filterName: string, enableFetch: boolean }) => {
  const { 
     tableList: userList,
  } = useTableFetch<any>({ 
    type: "POST", 
    url, 
    filterName: filterName, 
    enableFetch, 
    trigger: true, 
    paged: true 
  });
  return {
    userList,
  }
}