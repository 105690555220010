import React, { useEffect, useState } from 'react';

import { AutoComplete, Button, Popover, Tag } from 'antd';
import { useBodyFilterParams } from '../../../hook/customHooks/customHooks';
import { USER_FEATURE_FILTER } from '../../../constants/constants';
import { useUserList } from '../../../hook/customHooks/user';
import { useFeatureFunctions } from '../../../hook/customHooks/backdoor';

export const FeatureAutoComplete = (
  { id, feature, updateTrigger, setFieldValue, includeText = false }: 
  { id?: string, feature?: any, updateTrigger?: Function, setFieldValue?: Function, includeText?: boolean }
) => {
  const [enableFetch, setEnableFetch] = useState<boolean>(false);
  const { userList } = useUserList({ filterName: USER_FEATURE_FILTER + id, enableFetch });
  const { addBodyFilter: userAddBodyFilter } = useBodyFilterParams(USER_FEATURE_FILTER + id);
  const [inputValue, setInputValue] = useState<string>('');
  const [visible, setVisible] = useState<boolean>(false);
  const [numberSearch, setNumberSearch] = useState<number>(0);

  const {
    updateRoleUserFeature,
  } = useFeatureFunctions('');

  useEffect(() => {  
    userAddBodyFilter({
      keyword: inputValue,
      active: true,
    });
    setEnableFetch(numberSearch > 0);
    setNumberSearch(prevValue => prevValue + 1);
  }, [inputValue]);

  return (
    <Popover visible={visible} onVisibleChange={(visible => setVisible(visible))} trigger='click' content={
      <div>
        <div style={{ color: "#013369", fontWeight: "bold" }}>Search User</div>
        <AutoComplete
          searchValue={''}
          onChange={(e) => {
            setInputValue(e);
          }}
          placeholder={'Search user ...'}
          style={{ width: '250px', marginRight: '10px', borderRadius: '5px'}}
          onSelect={(value: string, value2) => {
            if (!!id) {
              updateRoleUserFeature(
                { ...feature, userList: [...feature.userList, { userId: value2.id }] }, 
                () => updateTrigger?.()
              );
              setInputValue('');
              setVisible(false);
            } else {
              setFieldValue?.('userList', [
                ...feature.userList, { userId: value2.id, name: value2.name }
              ]);
              setInputValue('');
              setVisible(false);
            }
          }}
          options={userList
            .filter(user => !feature.userList.some((addedUser: any) => addedUser.userId === user.id))
            .filter(() => inputValue.length > 0)
            .map(user => {
            return {
              value: `${user.name} ${user.email}`,
              id: user.id,
              name: user.name,
              key: user.id,
            }
          })}
          value={inputValue}
        />
      </div>
    }>
      {includeText ? <Button>New User</Button> : <Tag>+</Tag>}
      
    </Popover>
  )
}