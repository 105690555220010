import React from 'react';
import { RangePicker } from '../Shared/DatePicker/RangePicker';
import { Button, Form, Input, Pagination } from 'antd';
import { FeatureComponent } from '../Features/FeatureComponent';

export const Test = () => {
  return (
    <>
      <FeatureComponent featureCode="FEAT01" component={
        <Form.Item className='select-label-up'>
          <RangePicker 
            validDates={{
              endDate: (startDate, endDate) => {
                return endDate.diff(startDate, 'weeks') === 0;
              }
            }}
            // value={dateRange} 
          />
        </Form.Item>} 
      />
      <FeatureComponent featureCode="FEAT02" component={
        <Button>Click me 2!</Button>} 
      />
      <FeatureComponent featureCode="FEAT03" component={
        <Button>Click me 3!</Button>} 
      />
      <FeatureComponent featureCode="FEAT04" component={
        <Button>Click me 4!</Button>} 
      />
      <FeatureComponent featureCode="FEAT05" component={
        <Button>Click me 5!</Button>} 
      />
      <FeatureComponent featureCode="FEAT06" component={
        <Input defaultValue="Input 06" />} 
      />
      <FeatureComponent featureCode="FEAT07" component={
        <Input defaultValue="Input 07" />} 
      />
      <FeatureComponent featureCode="FEAT08" component={
        <Input defaultValue="Input 08" />} 
      />
      <FeatureComponent featureCode="FEAT09" component={
        <Input defaultValue="Input 09" />} 
      />
      <FeatureComponent featureCode="FEAT10" component={
        <Input defaultValue="Input 10" />} 
      />
      <FeatureComponent featureCode="FEAT11" component={
        <Pagination defaultCurrent={11} pageSize={1} total={50} />} 
      />
      <FeatureComponent featureCode="FEAT12" component={
        <Pagination defaultCurrent={12} pageSize={1} total={50} />} 
      />
      <FeatureComponent featureCode="FEAT13" component={
        <Pagination defaultCurrent={13} pageSize={1} total={50} />} 
      />
      <FeatureComponent featureCode="FEAT14" component={
        <Pagination defaultCurrent={14} pageSize={1} total={50} />} 
      />
      <FeatureComponent featureCode="FEAT15" component={
        <Pagination defaultCurrent={15} pageSize={1} total={50} />} 
      />
    </>
  )
}
