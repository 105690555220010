import React from 'react';
import { useAccountState } from '../../hook/hooks/account';


export const FeatureComponent = ({
  featureCode,
  component,
}: {
  featureCode: string,
  component: JSX.Element
}) => {
  const { account } = useAccountState();
  const { featureCodeList } = account;
  return (
    <>{featureCodeList.includes(featureCode) && component}</>
  )
}