import React, { useEffect, useState } from 'react';
import { Button, Tag, Tooltip } from 'antd';
import { Columns } from '../../Shared/TableTab/TabList';
import { ASCEND, DATE_FORMATS, EMPTY_ARRAY, FEATURES, TABLE_EDIT_NAME } from '../../../constants/constants';
import { DetailLayout } from '../../Shared/DetailLayout/DetailLayout';
import { NavigationBar } from '../../Shared/NavigationBar/NavigationBar';
import { InfiniteTable } from '../../Shared/Table/InfiniteTable';
import { API } from '../../../settings/server.config';
import { RoleSelector } from '../../Shared/TreeSelectors/RoleSelector';
import { useBodyFilterParams } from '../../../hook/customHooks/customHooks';
import { useFeatureFunctions } from '../../../hook/customHooks/backdoor';
import { FeatureAutoComplete } from '../Feature/FeatureAutoComplete';
import moment from 'moment';
import Icon from '../../Shared/CommomIcons/Icons';
import { ConfirmationModal } from '../../Shared/Modals/Modals';
import { SuccessMessage } from '../../Shared/Messages/Messages';

export const FeatureList = (
  { setShowFeatureDrawer, setFeatureId, roleTrigger, setRoleTrigger2 } : 
  { setShowFeatureDrawer: any, setFeatureId: Function, roleTrigger: number, setRoleTrigger2: Function }
) => {

  const { addBodyFilter } = useBodyFilterParams(FEATURES); 

  const {
    updateRoleUserFeature,
    deleteFeature,
  } = useFeatureFunctions('');

  const [, setTriggerEq] = useState<number>(0);

  const updateTrigger = () => {
    setTriggerEq((trigger) => {
      addBodyFilter({
        trigger: trigger
      });
      return trigger + 1;
    })
  }

  const handleRemoveUser = (feature: any, userId: string) => {
    updateRoleUserFeature(
      { ...feature, userList: [...feature.userList.filter((user: any) => user.userId !== userId)] }, 
      () => updateTrigger()
    );
  }

  const columns: Array<Columns> = [
    {
      title: 'Feature Code',
      dataIndex: 'featureCode',
      defaultSortOrder: ASCEND,
      key: 'code',
      sorter: false,
      exactWidth: 100,
    },
    {
      title: 'Feature Name',
      dataIndex: 'featureName',
      defaultSortOrder: ASCEND,
      key: 'code',
      sorter: false,
      exactWidth: 100,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      defaultSortOrder: ASCEND,
      key: 'description',
      sorter: false,
      exactWidth: 120,
    },
    {
      title: 'Created Date',
      dataIndex: 'createDate',
      defaultSortOrder: ASCEND,
      key: 'createDate',
      sorter: true,
      exactWidth: 100,
      render: (a: string) => {
        return a ? moment(new Date(a), DATE_FORMATS.yearMonthDay).local().format(DATE_FORMATS.monthDayYearHourMin) : '';
      }
    },
    {
      title: 'Users',
      dataIndex: 'users',
      defaultSortOrder: ASCEND,
      key: 'users',
      sorter: false,
      exactWidth: 320,
      render: (a: any, b: any) => {
        return (
          <div style={{ paddingRight: '5px' }}>
            {b.userList.filter((a: any, idx: number,) => {
              return idx < 2;
            }).map((user: any) => (
              <Tag
                key={user.userId}
                closable={true}
                onClose={() => handleRemoveUser(b, user.userId)}
              >
                {user.name}
              </Tag>
            ))}
            {b.userList.length > 2 && <Tooltip title={<div>
              {b.userList.filter((a: any, idx: number,) => {
                return idx >= 2;
              }).map((user: any) => (
                <div 
                  onClick={() => {
                    setShowFeatureDrawer(true);
                    setFeatureId(b?.id);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {user.name} {user.email}
                </div>
              ))}
            </div>}><Tag>...</Tag></Tooltip>}
            <FeatureAutoComplete id={b.id} feature={b} updateTrigger={updateTrigger}/>     
          </div>
        )
      }
    },
    {
      title: 'Roles',
      dataIndex: 'roleList',
      defaultSortOrder: ASCEND,
      key: 'roleList',
      sorter: false,
      render: (a: any, b: any) => {
        return <RoleSelector 
          key={b.id + ' ' + roleTrigger}
          disabled={false} 
          name={`FeatureRoleSelector${b.id}`} 
          onChange={(roles: Array<string>) => {
            updateRoleUserFeature({ ...b, roleList: roles }, () => {});
            
          }}
          selectedValues={b.roleList.map((roleId: string) => ({ roleId: roleId }))} 
        />
      },
    },
    {
      title: '',
      key: 'edit',
      dataIndex: 'size',
      align: 'right',
      show: true,
      render: (a: any, b: any) => {
        const toSessionStorage = {
          selectedValuesPerNode: {0: [...b.roleList]},
          selectedValuesPerLevel: [[...b.roleList]],
        }
        sessionStorage.setItem(`FeatureRoleSelector${b.id}`, JSON.stringify(toSessionStorage));
        return (
          <>
            <Button
              id={`eEditFeature`}
              onClick={(e) => {
                setShowFeatureDrawer(true);
                setFeatureId(b?.id);
                setRoleTrigger2((prevValue: number) => prevValue + 1);
              }}
              style={{border: 'none', boxShadow: 'none'}}>
              <img
                className="img-h anticon"
                src={'/images/edit.svg'}
                alt="" width="18px"
                height="18px"
              />
            </Button>
          </>
        );
      }
    },
    {
      title: '',
      key: 'delete',
      dataIndex: 'featureName',
      align: 'right',
      show: true,
      width: 2,
      render: (a: any, b: any) => {
        return (
          <>
            <Button
              id={`eDeleteEqTemplate-${b.id}`}
              onClick={(e) => {
                ConfirmationModal('Delete', `Are you sure to delete this Feature: ${a}?`, () => {
                  deleteFeature(b.id, () => {
                    updateTrigger()
                    SuccessMessage({ description: `Feature: ${a} was deleted`})
                  })
                });
              }}
              style={{border: 'none', boxShadow: 'none'}}>
              <Icon.Delete 
                width="18px"
                style={{  
                  border: '0px', 
                  marginRight: '5px', 
                  display:'flex', 
                  alignItems: 'center', 
                  justifyContent:'center', 
                  background: 'transparent',
                  cursor: 'pointer',
                }}
              />
            </Button>
          </>
        );
      }
    },
  ];

  return (
    <DetailLayout 
      disableBackButton={true}
    >
      <div className="card-container">
        <NavigationBar
          navTitle={
            <div className="navigationbar-header-configuration">
              <span className="navigation-bar-configuration">Features</span>
            </div>
          }
          rightBar={[
            <div style={{marginRight: '13px'}}>
              <Button
                icon={<img className="img-h anticon" src="/images/plus-icon.svg" alt="" width="14px"/>}
                className="btn-green"
                onClick={() => {
                  setShowFeatureDrawer(true);
                }}
              > NEW FEATURE </Button>
            </div>
          ]}
        />
        <InfiniteTable
          url={API.FEATURE.PAGE()}
          fetchType="GET"
          columns={columns}
          componentName={''}
          filters={EMPTY_ARRAY}
          filterName={FEATURES}
          columnEditName={TABLE_EDIT_NAME.FEATURES_COLUMN}
          defaultFiltersObject={{
            sort: {
              params: ['createDate', 'asc'],
            },
            page: {
              params: ['0']
            },
            size: {
              params: [50]
            },
          }}
          paged
        />
      </div>
    </DetailLayout>
  );
};
