import React, { useEffect, useState } from 'react';
import { Col, Form, Input, Row, Tag } from 'antd';
import { useFormik } from 'formik';

import { Drawer } from '../../Shared/Drawer/Drawer';
import { FEATURES } from '../../../constants/constants';
import { FeatureBackTool, useFeatureFunctions } from '../../../hook/customHooks/backdoor';
import { ConfirmationModal } from '../../Shared/Modals/Modals';
import { featureDrawerValidators } from '../../../constants/validators';
import { useBodyFilterParams } from '../../../hook/customHooks/customHooks';
import { RoleSelector } from '../../Shared/TreeSelectors/RoleSelector';
import { FeatureAutoComplete } from '../Feature/FeatureAutoComplete';

export const FeaturesDrawer = ({showDrawer, featureId, setFeatureId, closeDrawer, filterName, setRoleTrigger, roleTrigger2 }: {
  showDrawer: boolean,
  closeDrawer?: Function,
  filterName?: string,
  featureId?: string,
  setFeatureId?: any,
  setRoleTrigger: any
  roleTrigger2: number,
}) => {

  const {
    feature, 
    createFeature,
    loadFeature,
    updateFeature,
  } = useFeatureFunctions(featureId ?? '');

  const { TextArea } = Input;

  useEffect(() => {
    if((featureId + '').length > 0) {
      loadFeature();
    } else {
      const toSessionStorage = {
        selectedValuesPerNode: {0: []},
        selectedValuesPerLevel: [[]],
      }
      sessionStorage.setItem(`FeatureRoleSelector-now-drawer`, JSON.stringify(toSessionStorage));
    }
  }, [featureId, loadFeature]);
  
  const {values, handleChange, setFieldValue, validateForm, resetForm, errors} = useFormik({
    initialValues: {
      description: feature.description ?? '',
      featureCode: feature.featureCode ?? '', 
      featureName: feature.featureName ?? '',
      id: feature.id ?? '',
      roleList: feature.roleList.length > 0 ? feature.roleList : [],
      userList: feature.userList.length > 0 ? feature.userList : [],
    },
    validationSchema: featureDrawerValidators,
    enableReinitialize: true,
    onSubmit(values: FeatureBackTool) {
      //intentionally empty
    }
  });
  
  const { bodyFilter: featureBodyFilter, addBodyFilter } = useBodyFilterParams(FEATURES);
  const [checkSubmitValidator, setCheckSubmitValidator] = useState<boolean>(false);
  const [, setTrigger] = useState<number>(featureBodyFilter?.trigger ?? 0);

  useEffect(() => {
    if((featureId + '').length > 0) {
      const toSessionStorage = {
        selectedValuesPerNode: {0: [...values.roleList]},
        selectedValuesPerLevel: [[...values.roleList]],
      }
      sessionStorage.setItem(`FeatureRoleSelector${featureId}-drawer`, JSON.stringify(toSessionStorage));
    }
  }, [roleTrigger2, featureId])
  
  return (
    <Drawer
      title={`${featureId ? 'EDIT' : 'NEW'} FEATURE`}
      onClose={() => {
        resetForm();
        closeDrawer?.();
        setCheckSubmitValidator(false);
        setFeatureId('');
      }}
      canModify={true}
      width="42%"
      visible={showDrawer}
      onChange={() => {
        setCheckSubmitValidator(true);
        validateForm(values).then((result) => {
          if(Object.keys(result).length === 0) {
            if(featureId) {
              ConfirmationModal('Update Feature', 
                <div>
                  Are you sure to update the feature <b>{`${values.featureCode}`}</b>?
                </div>
              , () => {
                setCheckSubmitValidator(false);
                updateFeature(values, () => {
                  setTrigger(prevState => {
                    addBodyFilter({
                      trigger: prevState + 1,
                    })
                    return prevState + 1;
                  })
                  closeDrawer?.();
                  setFeatureId('');
                  setRoleTrigger((prevValue: any) => prevValue + 1);
                });
              });
            } else {
              ConfirmationModal('Create Feature', 
                <div>
                  Are you sure to create a new feature <b>{`${values.featureCode}`}</b>?
                </div>
              , () => {
                setCheckSubmitValidator(false);
                createFeature(values, () => {
                  setTrigger(prevState => {
                    addBodyFilter({
                      trigger: prevState + 1,
                    });
                    return prevState + 1;
                  });
                  closeDrawer?.();
                  setFeatureId('');
                });
              })
            }
          }
        });
      }}
      // alertResponse={rebuildResponse}
      extraButton={
        <></>
      }
      enableExtraButton={false}
    >
      <Form layout="vertical" className="modal-activity" onSubmitCapture={() => {}/*handleSubmit*/}>
        <div className="drawer_config">
          <h5>Information</h5>
          <div className="">
            <div className="drawer_body_config" style={{ height: '80vh' }}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.Item label="Feature Code">
                    <Input 
                      maxLength={20}
                      name="featureCode"
                      onChange={handleChange}
                      placeholder="Insert feature code"
                      value={values.featureCode}
                    />
                  </Form.Item>
                  {checkSubmitValidator && errors.featureCode && <span className="form-feedback">{errors.featureCode}</span>}
                </Col>
                <Col span={24}>
                  <Form.Item label="Feature Name">
                    <Input 
                      maxLength={34}
                      name="featureName"
                      onChange={handleChange}
                      placeholder="Insert feature name"
                      value={values.featureName}
                    />
                  </Form.Item>
                  {checkSubmitValidator && errors.featureName && <span className="form-feedback">{errors.featureName}</span>}
                </Col>
                <Col span={24}>
                  <Form.Item label="Feature Description">
                    <TextArea 
                      name="description"
                      placeholder="Enter the description"
                      onChange={(e) => handleChange(e)}
                      autoSize={{ minRows: 3, maxRows: 3}}
                      value={values.description}
                      maxLength={200}
                    />
                  </Form.Item>
                  {checkSubmitValidator && errors.description && <span className="form-feedback">{errors.description}</span>}
                </Col>
                <Col span={12}>
                  <Form.Item label="Roles" className="role-selector">
                    <RoleSelector 
                      key={featureId + ' ' + roleTrigger2}
                      disabled={false}
                      onChange={(values) => {
                        setFieldValue('roleList', values)
                      }}
                      name={`FeatureRoleSelector${!!featureId ? values.id : '-now'}-drawer`}
                      selectedValues={values.roleList.map(roleId => ({ roleId: roleId }))}
                    />
                  </Form.Item>
                  {checkSubmitValidator && errors.roleList && <span className="form-feedback">{errors.roleList}</span>}
                </Col>
                <Col span={12} />
                <Col span={24}>
                  <Form.Item label={
                    <div>
                      <span style={{ paddingRight: '5px' }}>Users</span>
                      <FeatureAutoComplete feature={values} setFieldValue={setFieldValue} />
                    </div>
                  }>
                    {values.userList.map((user: any) => {
                      return (
                        <Tag
                          style={{ margin: '3px' }}
                          key={user.userId}
                          closable={true}
                          onClose={() => {
                            setFieldValue?.(
                              'userList', 
                              values.userList.filter(userIter => userIter.userId !== user.userId)
                            );
                          }}
                        >
                          {user.name}
                        </Tag>)
                    })}
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};
