import React from 'react';
import { RoleState } from '../store/types';
import { useRolesList } from '../hook/hooks/roles';

type RolesContextType = {
  roles: RoleState[],
};

const RolesContext = React.createContext<RolesContextType>({
  roles: []
});

export const RolesProvider: React.FC = ({ children }) => {
  const { roles } = useRolesList();
  return <RolesContext.Provider value={{ roles: roles }}>
    {children}
  </RolesContext.Provider>
}

export const useRolesProvider = () => React.useContext(RolesContext);